// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const ShippingForm = ({ userId }) => {
//   const [address, setAddress] = useState({
//     street: '',
//     city: '',
//     state: '',
//     zip: '',
//     country: '',
//   });

//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Store address in local state or send to backend if needed
//     localStorage.setItem('shippingAddress', JSON.stringify(address));
//     navigate("/payment"); // Navigate to the next step
//   };

//   return (
//     <div className="max-w-lg mx-auto">
//       <h2 className="text-2xl font-bold">Shipping Information</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Street Address"
//           value={address.street}
//           onChange={(e) => setAddress({ ...address, street: e.target.value })}
//         />
//         <input
//           type="text"
//           placeholder="City"
//           value={address.city}
//           onChange={(e) => setAddress({ ...address, city: e.target.value })}
//         />
//         <input
//           type="text"
//           placeholder="State"
//           value={address.state}
//           onChange={(e) => setAddress({ ...address, state: e.target.value })}
//         />
//         <input
//           type="text"
//           placeholder="ZIP Code"
//           value={address.zip}
//           onChange={(e) => setAddress({ ...address, zip: e.target.value })}
//         />
//         <input
//           type="text"
//           placeholder="Country"
//           value={address.country}
//           onChange={(e) => setAddress({ ...address, country: e.target.value })}
//         />
//         <button type="submit" className="bg-green-600 text-white py-2 px-4">
//           Continue to Payment
//         </button>
//       </form>
//     </div>
//   );
// };

// export default ShippingForm;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ShippingForm = () => {
  const [shippingDetails, setShippingDetails] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'United States',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails({ ...shippingDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('shippingDetails', JSON.stringify(shippingDetails));
    navigate('/payment');
  };

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Checkout</h2>
        <form onSubmit={handleSubmit} className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div>
            <div>
              <h2 className="text-lg font-medium text-gray-900">Shipping Information</h2>
              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <input
                    id="first-name"
                    name="firstName"
                    type="text"
                    value={shippingDetails.firstName}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <input
                    id="last-name"
                    name="lastName"
                    type="text"
                    value={shippingDetails.lastName}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    value={shippingDetails.address}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={shippingDetails.city}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    value={shippingDetails.state}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                    ZIP / Postal code
                  </label>
                  <input
                    id="zip"
                    name="zip"
                    type="text"
                    value={shippingDetails.zip}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    value={shippingDetails.country}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 mt-10 pt-10">
              <button
                type="submit"
                className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                Continue to Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingForm;
