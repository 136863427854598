import instance from "./index";

const getCategories = async () => {
  try {
    const response = await instance.get("/categories");
    console.log("API Response:", response); // Log the full response to check its structure
    return response.data.categories; // Make sure the path matches the actual response structure
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export { getCategories };
