import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { decodeToken } from '../api/storage'; // Assuming you decode JWT to get userId
import instance from '../api';

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Decode the token to extract the userId
  const decodedToken = decodeToken();
  const userId = decodedToken?.user?.id;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await instance.get(`/orders/${userId}`);
        setOrders(response.data.orders || []);  // Fallback to empty array if no orders
        setIsLoading(false);
      } catch (error) {
        setError('Error fetching orders');
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchOrders();
    }
  }, [userId]);

  if (isLoading) {
    return <div>Loading your orders...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <NavBar />
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-4">My Orders</h1>
        {orders.length === 0 ? (
          <p>You have no orders yet.</p>
        ) : (
          <div className="space-y-4">
            {orders.map((order) => (
              <div key={order._id} className="border p-4 rounded-lg shadow-sm bg-white">
                <h2 className="text-xl font-medium">Order #{order._id}</h2>
                <p className="text-gray-600">Date: {new Date(order.date).toLocaleDateString()}</p>
                <p className="text-gray-600">Total: KWD {order.total?.toFixed(2) || 'N/A'}</p>
                <p className="text-gray-600">Status: {order.status}</p>
                <h3 className="font-semibold mt-2">Items:</h3>
                <ul className="pl-4 list-disc">
                  {order.products?.length > 0 ? (
                    order.products.map(({ product, quantity }) => (
                      <li key={product?._id || Math.random()}>
                        {product?.name || 'Unknown Product'} - Quantity: {quantity} - Price: KWD
                        {typeof product?.price === 'number' ? product.price.toFixed(2) : 'N/A'}
                      </li>
                    ))
                  ) : (
                    <li>No products found for this order.</li>
                  )}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
