// src/api/cart.js
import instance from "./index";
// Function to create or update the cart
export const addToCart = async ({ userId, productId, quantity = 1 }) => {
  try {
    const response = await instance.post("/cart", {
      user: userId,
      productId: productId,
      quantity: quantity,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding product to cart:", error);
    throw error;
  }
};

// Function to create or update the cart
export const removeOneFromCart = async ({
  userId,
  productId,
  quantity = -1,
}) => {
  try {
    const response = await instance.post("/cart", {
      user: userId,
      productId: productId,
      quantity: quantity,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding product to cart:", error);
    throw error;
  }
};

// Fetch the cart for a specific user
export const getCart = async (userId) => {
  try {
    const response = await instance.get(`/cart/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error);
    throw error;
  }
};

export const removeProductFromCart = async (userId, productId) => {
  if (!userId || !productId) {
    console.error("UserId or ProductId is missing");
    return;
  }

  try {
    const response = await instance.delete(`/cart/${userId}/${productId}`);
    return response.data;
  } catch (error) {
    console.error("Error removing product from cart:", error);
    throw error;
  }
};

// Function to update the product quantity in the cart
export const updateProductQuantity = async ({
  userId,
  productId,
  quantity,
}) => {
  try {
    const response = await instance.put(`/cart`, {
      user: userId,
      productId: productId,
      quantity: quantity,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating product quantity in cart:", error);
    throw error;
  }
};
