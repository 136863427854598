import React from 'react';
import { Link } from 'react-router-dom';

const OrderConfirmation = () => {
  return (
    <div className="max-w-lg mx-auto text-center">
      <h2 className="text-2xl font-bold">Thank You for Your Order!</h2>
      <p>Your order has been successfully placed. You will receive a confirmation email shortly.</p>
      <Link to="/ShopByCategory" className="bg-green-600 text-white py-2 px-4 mt-4 inline-block">
        Continue Shopping
      </Link>
    </div>
  );
};

export default OrderConfirmation;
