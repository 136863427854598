import React from "react";
import { CheckIcon, ClockIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import {
  addToCart,
  getCart,
  removeOneFromCart,
  removeProductFromCart,
  updateProductQuantity,
} from "../api/cart"; // Assuming you have deleteProductFromCart API
import { Link, useNavigate } from "react-router-dom"; // For navigation
import { decodeToken } from "../api/storage"; // To decode the JWT token and get the userId
import placeHolderImage from "../assests/widway.jpg";
import { Button } from "@headlessui/react";

const Cart = () => {
  const navigate = useNavigate();
  const serviceAndLaborFee = 15; // Fixed service and labor fee

  // Decode the token to extract the userId
  const decodedToken = decodeToken();
  const userId = decodedToken?.user?.id;

  console.log(userId);

  // Fetch the cart data for the user using react-query
  const {
    data: cartData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["cart", userId],
    queryFn: () => getCart(userId), // Pass userId to getCart function
    enabled: !!userId, // Only fetch cart if userId is available
  });

  console.log(cartData);
  if (isLoading) return <div>Loading cart...</div>;
  if (error) return <div>Error fetching cart: {error.message}</div>;

  const products = cartData?.cart?.products || []; // Fallback to empty array if cart is empty

  // Function to handle removing a product from the cart
  const handleRemoveProduct = async (productId) => {
    console.log("UserId:", userId, "ProductId:", productId); // Log the userId and productId to debug

    try {
      await removeProductFromCart(userId, productId); // Ensure userId and productId are passed correctly
      window.location.reload(); // Optionally refetch instead of reloading the page
    } catch (error) {
      console.error("Error removing product:", error);
    }
  };

  // Function to handle updating the quantity of a product in the cart
  const handleAddQuantity = async (productId) => {
    if (!userId) {
      alert("You need to be logged in to add items to the cart.");
      return;
    }
    try {
      await addToCart({ userId, productId, quantity: 1 });
      window.location.reload();
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("Failed to add product to cart.");
    }
  };

  const delOneFromCart = async (productId, quantity) => {
    console.log("delOneFromCart", productId, quantity);
    if (!userId) {
      alert("You need to be logged in to add items to the cart.");
      return;
    }
    try {
      if (quantity === 1) {
        await removeProductFromCart(userId, productId);
        window.location.reload();
        alert("one Product removred from cart!");
      } else {
        await removeOneFromCart({ userId, productId, quantity: -1 });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("Failed to remove one product from cart.");
    }
  };

  const handleCheckout = (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate("/checkout"); // Navigate to the shipping step
  };

  // Calculate subtotal and total including service and labor fee
  const subtotal = products
    .reduce(
      (total, { product, quantity }) => total + product.price * quantity,
      0
    )
    .toFixed(2);
  const totalWithServiceFee = (
    parseFloat(subtotal) + serviceAndLaborFee
  ).toFixed(2);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:px-0">
        <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Shopping Cart
        </h1>

        {products.length === 0 ? (
          <div className="text-center mt-12">
            <p>Your cart is empty.</p>
            <Link
              to="/ShopByCategory"
              className="mt-4 inline-block rounded-md bg-green-600 px-4 py-2 text-white hover:bg-green-700"
            >
              Continue Shopping
            </Link>
          </div>
        ) : (
          <form className="mt-12">
            <section aria-labelledby="cart-heading">
              <h2 id="cart-heading" className="sr-only">
                Items in your shopping cart
              </h2>

              <ul className="divide-y divide-gray-200 border-b border-t border-gray-200">
                {products.map(({ product, quantity }) => (
                  <li key={product._id} className="flex py-6">
                    <div className="flex-shrink-0">
                      <img
                        alt={product.name}
                        src={product.imageSrc || placeHolderImage}
                        className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                      <div>
                        <div className="flex justify-between">
                          <h4 className="text-sm">
                            <Link
                              to={`/products/${product._id}`}
                              className="font-medium text-gray-700 hover:text-gray-800"
                            >
                              {product.name}
                            </Link>
                          </h4>
                          <p className="ml-4 text-sm font-medium text-gray-900">
                            ${product.price}
                          </p>
                        </div>
                        {/* <p className="mt-1 text-sm text-gray-500">
                          Qty: {quantity}
                        </p>
                        <Button onClick={() => handleAddQuantity(product._id)}>
                          +
                        </Button>
                        <p> " "</p>
                        <Button
                          onClick={() => delOneFromCart(product._id, quantity)}
                        >
                          -
                        </Button> */}
                        <div className="flex items-center space-x-4 mt-1">
                          {/* Quantity Control */}
                          {quantity === 1 ? (
                            <Button
                              onClick={() => handleRemoveProduct(product._id)} // Remove the product if clicked
                              className="text-red-600  hover:bg-gray-300 rounded-full h-6 w-6 flex items-center justify-center"
                            >
                              <TrashIcon
                                className="h-6 w-6 text-gray-500"
                                aria-hidden="true"
                                title="Remove item"
                              />
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                delOneFromCart(product._id, quantity)
                              }
                              className="text-red-600 bg-gray-200 hover:bg-gray-300 rounded-full h-6 w-6 flex items-center justify-center"
                            >
                              -
                            </Button>
                          )}

                          {/* Display the current quantity */}
                          <p className="text-sm font-medium text-gray-700">
                            {quantity}
                          </p>

                          {/* Add quantity button */}
                          <Button
                            onClick={() => handleAddQuantity(product._id)}
                            className="text-green-600 bg-gray-200 hover:bg-gray-300 rounded-full h-6 w-6 flex items-center justify-center"
                          >
                            +
                          </Button>
                        </div>
                      </div>

                      <div className="mt-4 flex flex-1 items-end justify-between">
                        <p className="flex items-center space-x-2 text-sm text-gray-700">
                          {product.inStock ? (
                            <CheckIcon
                              aria-hidden="true"
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                            />
                          ) : (
                            <ClockIcon
                              aria-hidden="true"
                              className="h-5 w-5 flex-shrink-0 text-gray-300"
                            />
                          )}
                          {/* <span>
                            {product.inStock
                              ? "In stock"
                              : `Will ship in ${product.leadTime}`}
                          </span> */}
                        </p>
                        <div className="ml-4">
                          <button
                            type="button"
                            onClick={() => handleRemoveProduct(product._id)} // Call the remove function
                            className="text-sm font-medium text-red-600 hover:text-red-500"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </section>

            {/* Order summary */}
            <section aria-labelledby="summary-heading" className="mt-10">
              <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2>

              <div>
                <dl className="space-y-4">
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-medium text-gray-900">
                      Subtotal
                    </dt>
                    <dd className="ml-4 text-base font-medium text-gray-900">
                      KWD {subtotal}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-medium text-gray-900">
                      Service & Labor Fee
                    </dt>
                    <dd className="ml-4 text-base font-medium text-gray-900">
                      KWD {serviceAndLaborFee}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-bold text-gray-900">Total</dt>
                    <dd className="ml-4 text-base font-bold text-gray-900">
                      KWD {totalWithServiceFee}
                    </dd>
                  </div>
                </dl>
                <p className="mt-1 text-sm text-gray-500">
                  Shipping and taxes will be calculated at checkout.
                </p>
              </div>

              <div className="mt-10">
                <button
                  onClick={handleCheckout} // Add onClick handler to navigate
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-green-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Checkout
                </button>
              </div>

              <div className="mt-6 text-center text-sm">
                <p>
                  or{" "}
                  <Link
                    to="/ShopByCategory"
                    className="font-medium text-green-600 hover:text-green-500"
                  >
                    Continue Shopping
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </p>
              </div>
            </section>
          </form>
        )}
      </div>
    </div>
  );
};

export default Cart;
