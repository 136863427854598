//src/pages/ContactUs
import React from 'react'
// import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import NavBar from '../components/NavBar'

const ContactUs = () => {
    const navigation = {
        solutions: [
          { name: 'Marketing', href: '#' },
          { name: 'Analytics', href: '#' },
          { name: 'Commerce', href: '#' },
          { name: 'Insights', href: '#' },
        ],
        support: [
          { name: 'Pricing', href: '#' },
          { name: 'Documentation', href: '#' },
          { name: 'Guides', href: '#' },
          { name: 'API Status', href: '#' },
        ],
        company: [
          { name: 'About', href: '#' },
          { name: 'Blog', href: '#' },
          { name: 'Jobs', href: '#' },
          { name: 'Press', href: '#' },
          { name: 'Partners', href: '#' },
        ],
        legal: [
          { name: 'Claim', href: '#' },
          { name: 'Privacy', href: '#' },
          { name: 'Terms', href: '#' },
        ],
        social: [
            {
                name: 'whatsapp',
      href: 'https://wa.me/+96598970885?text=Hi%20BNCHR%2B%0AI%20just%20visited%20your%20website..',
      icon: (props) => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12 2C6.486 2 2 6.486 2 12c0 1.936.55 3.83 1.583 5.48L2 22l4.671-1.529C8.173 21.472 10.057 22 12 22c5.514 0 10-4.486 10-10S17.514 2 12 2zm4.789 14.3c-.2.56-1.136 1.059-1.59 1.106-.418.043-.932.061-1.498-.089-.344-.086-.788-.256-1.35-.497-2.38-1.04-3.931-3.393-4.05-3.55-.116-.158-.963-1.282-.963-2.442 0-1.16.61-1.731.826-1.972.216-.24.474-.3.63-.3.16 0 .315.002.452.008.144.007.34-.055.535.407.198.468.676 1.589.737 1.704.06.114.1.253.02.41-.075.155-.11.253-.217.388-.105.137-.221.3-.315.403-.105.113-.214.235-.092.46.122.226.543.89 1.163 1.442.8.714 1.47.933 1.703 1.034.234.1.371.087.51-.053.137-.14.587-.663.744-.891.156-.228.317-.189.53-.114.218.076 1.379.65 1.618.768.237.119.395.176.45.274.06.101.06.587-.14 1.148z" />
        </svg>
      ),
              },
          {
            name: 'Instagram',
            href: 'https://www.instagram.com/bnchrplus/',
            icon: (props) => (
              <svg fill="currentColor" viewBox="0 0 24 24" {...props} >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            ),
          },
          {
            name: 'X',
            href: 'https://x.com/BnchrPlus',
            icon: (props) => (
              <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
              </svg>
            ),
          },
         
         
        ],
      }
  return (
    <div className="relative w-full min-h-screen flex flex-col bg-white">
        <NavBar />
      <div className="flex flex-col justify-center items-center flex-grow w-full px-4 py-16 sm:px-6 lg:px-8 lg:py-24">
        <div className="relative text-center max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
  At Bncher Plus, we pride ourselves on providing top-tier automotive services. From routine maintenance to complex repairs, our team is dedicated to keeping your vehicle running smoothly and safely. Reach out to us for expert advice, reliable service, and a commitment to excellence in every aspect of automotive care.
</p>

            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
            <div className="flex space-x-6  justify-center gap-x-4">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-12 w-12" />
                </a>
              ))}
            </div>
              {/* Centered Contact Information */}
              {/* <div className="flex justify-center gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href="tel:+965 9897 0885" className="hover:text-gray-900">
                    +965 9897 0885
                  </a>
                </dd>
              </div>
              <div className="flex justify-center gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href="mailto:hello@example.com" className="hover:text-gray-900">
                    hello@example.com
                  </a>
                </dd>
              </div> */}
            </dl>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
