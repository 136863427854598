import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useQuery } from "@tanstack/react-query";
import { getProducts } from "../api/product";
import placeHolderImage from "../assests/widway.jpg";
import { getCategories } from "../api/category";
import { addToCart } from "../api/cart";
import { decodeToken } from "../api/storage"; // Import the decodeToken function

const ShopByCategory = () => {
  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: getProducts,
  });

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  const decodedToken = decodeToken();
  console.log("Decoded Token:", decodedToken);

  // Extract userId from the nested user object
  const userId = decodedToken?.user?.id;

  console.log("userId", userId);

  console.log("Categories:", categories);

  // Function to handle adding a product to the cart
  const handleAddToCart = async (productId) => {
    if (!userId) {
      alert("You need to be logged in to add items to the cart.");
      return;
    }
    try {
      await addToCart({ userId, productId, quantity: 1 });
      alert("Product added to cart!");
      window.location.reload();
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("Failed to add product to cart.");
    }
  };

  return (
    <div className="bg-white">
      <NavBar />

      {/* <div className="bg-white p-10">
        <ShopFilter />
        {categories?.map((category) => (
          <div
            key={category._id}
            className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8"
          >
            <h2 className="text-xl font-bold text-gray-900">{category.name}</h2>
          </div>
        ))}
      </div> */}

      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="text-xl font-bold text-gray-900">Enjoy Shopping</h2>

          <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {products?.map((product) => (
              <div key={product._id}>
                <div className="relative">
                  <div className="relative h-72 w-full overflow-hidden rounded-lg">
                    <img
                      alt={product.imageAlt}
                      src={product.imageSrc || placeHolderImage}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="relative mt-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {product.name}
                    </h2>
                  </div>
                  <div className="relative mt-1">
                    <p className="text-sm text-gray-500">
                      {product.description}
                    </p>
                  </div>
                  <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                    <div
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                    />
                    <p className="relative text-lg font-semibold text-white">
                      KD {product.price}
                    </p>
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    onClick={() => handleAddToCart(product._id)}
                    className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200"
                  >
                    Add to cart<span className="sr-only">, {product.name}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopByCategory;
