


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createOrder } from "../api/order";
import { useQuery } from "@tanstack/react-query";
import { getCart } from "../api/cart"; 
import { decodeToken } from "../api/storage"; 
import instance from "../api";

const CheckoutPage = () => {
  // Decode the token to extract the userId
  const decodedToken = decodeToken();
  const userId = decodedToken?.user?.id;

  // Fetch the cart data for the user using react-query
  const { data: cartData, isLoading, error } = useQuery({
    queryKey: ["cart", userId],
    queryFn: () => getCart(userId), 
    enabled: !!userId, // Only fetch cart if userId is available
  });

  const cartId = cartData?.cart?._id;
  const products = cartData?.cart?.products || []; // Products array from the cart
  const subtotal = products.reduce((sum, { product, quantity }) => sum + product.price * quantity, 0); // Calculate total
  const total = subtotal + 15; // const 
  const [shippingDetails, setShippingDetails] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails({ ...shippingDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (paymentMethod === "online") {
      setIsPaymentStep(true);
    } else {
      try {
        setIsSubmitting(true);

        console.log("userId", userId);
        console.log("shippingDetails", shippingDetails);
        console.log("products", products);
        console.log("total", total);

          // Call the createOrder API function
      await createOrder(userId, shippingDetails, products, total);


        setIsSubmitting(false);
        navigate("/confirmation"); // Redirect to confirmation page after successful order
      } catch (error) {
        console.error("Order creation failed:", error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Checkout</h2>

        <form onSubmit={handleSubmit} className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          {/* Shipping Information */}
          <div>
            <h2 className="text-lg font-medium text-gray-900">Shipping Information</h2>
            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <input
                  id="first-name"
                  name="firstName"
                  type="text"
                  value={shippingDetails.firstName}
                  onChange={handleShippingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  value={shippingDetails.lastName}
                  onChange={handleShippingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  value={shippingDetails.address}
                  onChange={handleShippingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Phone (optional)
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={shippingDetails.phone}
                  onChange={handleShippingChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>

          {/* Payment Method
          <div className="mt-10">
            <h2 className="text-lg font-medium text-gray-900">Payment Method</h2>
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Choose Payment Method:</label>
                <div className="mt-1">
                  <select
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Select a payment method</option>
                    <option value="cod">Cash on Delivery</option>
                    <option value="online">Online Payment</option>
                  </select>
                </div>
              </div>
            </div> */}

              {/* Payment Method */}
          <div className="mt-10">
            <h2 className="text-lg font-medium text-gray-900">Payment Method</h2>
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Choose Payment Method:</label>
                <div className="mt-1">
                  <select
                    name="paymentMethod"
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Select a payment method</option>
                    <option value="cod">Cash on Delivery</option>
                    {/* <option value="online">Online Payment</option> */}
                  </select>
                </div>
              </div>
            </div>

            {/* Payment step for online payment */}
            {isPaymentStep && paymentMethod === "online" && (
              <div className="mt-6">
                <h2 className="text-lg font-medium text-gray-900">Payment Information</h2>
                {/* Payment form */}
                {/* You would integrate the Tap Payment flow here */}
              </div>
            )}
          </div>

          {/* Order Review */}
          <div className="mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900">Review Your Order</h2>
            <div className="mt-4">
              <p className="text-sm font-medium">Shipping to:</p>
              <p>{`${shippingDetails.firstName} ${shippingDetails.lastName}, ${shippingDetails.address}`}</p>

              <p className="mt-4 text-sm font-medium">Payment Method:</p>
              <p>{paymentMethod === "online" ? "Online Payment" : "Cash on Delivery"}</p>

              <div className="mt-6">
                <button
                  type="submit"
                  className="w-full rounded-md border border-transparent bg-green-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "Place Order"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutPage;
