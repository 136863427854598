// import { jwtDecode } from "jwt-decode";

// const storeToken = (token) => {
//   localStorage.setItem("token", token);
// };

// const getToken = () => {
//   return localStorage.getItem("token");
// };

// const removeToken = () => {
//   localStorage.removeItem("token");
// };

// const checkToken = () => {
//   const token = getToken();
//   if (token) {
//     const decoded = jwtDecode(token);
//     const currentTime = Date.now() / 1000;
//     if (decoded.exp < currentTime) {
//       removeToken();
//       return false;
//     }
//     return true;
//   }
//   return false;
// };

// export { storeToken, getToken, removeToken, checkToken };

// src/api/storage.js
import { jwtDecode } from 'jwt-decode';  // Ensure you import jwtDecode if not already

const storeToken = (token) => {
  localStorage.setItem("token", token);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const decodeToken = () => {
  const token = getToken();
  if (token) {
    return jwtDecode(token);  // Return the decoded token
  }
  return null;
};

const checkToken = () => {
  const token = getToken();
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      removeToken();
      return false;
    }
    return true;
  }
  return false;
};

export { storeToken, getToken, removeToken, checkToken, decodeToken };

