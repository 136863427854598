import instance from "./index";

// export const createOrder = async (orderData) => {
//     try {
//       const response = await instance.post('/orders', orderData);
//       return response.data;
//     } catch (error) {
//       console.error('Error creating order:', error);
//       throw error;
//     }
//   };

export const createOrder = async (userId, shippingDetails, products, total) => {
    try {
      const response = await instance.post("/orders", {
        userId,
        shippingDetails,
        products: products.map(({ product, quantity }) => ({
          product: product._id,  // Assuming product._id exists
          quantity,
        })),
        total,
      });
  
      return response.data;
    } catch (error) {
      console.error("Error creating order: ", error);
      throw error;
    }
  };
  