import instance from "./index";

const getProducts = async () => {
  try {
    const response = await instance.get("/products");
    return response.data.products; // Access the `products` array directly
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export { getProducts };
