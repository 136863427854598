// src/api/auth.js
import instance from "./index";
import { storeToken } from "./storage";

 const login = async (userInfo) => {
  console.log(userInfo)
  try {
    const response = await instance.post('/auth/login', {
      email: userInfo.email,
      password: userInfo.password,
    });

    const token = response.data.token;  // Assuming the token is in the response
    if (token) {
      localStorage.setItem("token", token);  // Store the token in localStorage
    }

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Login failed");
  }
};

const register = async (userData) => {
  try {
    const response = await instance.post('/auth/register', userData);
    return response.data;
  } catch (error) {
    console.error('Registration error:', error.response?.data || error.message);
    throw error;
  }
};


const logout = () => {
  localStorage.removeItem("token");  // Remove the token from localStorage
};

export { login, register , logout};
