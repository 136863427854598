import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../api/order'; // Ensure this API exists

const OrderReview = ({ userId }) => {
  const navigate = useNavigate();
  const cart = JSON.parse(localStorage.getItem('cart')); // You can replace this with actual cart data from the backend
  const address = JSON.parse(localStorage.getItem('shippingAddress')); // Get address from local storage or state
  const total = cart?.products.reduce((sum, { product, quantity }) => sum + product.price * quantity, 0) || 0;

  const handleConfirmOrder = async () => {
    try {
      const orderData = {
        userId,
        address,
      };
      const response = await createOrder(orderData); // Make the API call to create the order
      console.log('Order created successfully:', response.data);
      navigate("/confirmation");
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <div className="max-w-lg mx-auto">
      <h2 className="text-2xl font-bold">Order Review</h2>
      <h3>Shipping Address:</h3>
      <p>{address.street}, {address.city}, {address.state}, {address.zip}, {address.country}</p>
      <h3>Order Total: ${total.toFixed(2)}</h3>

      <button onClick={handleConfirmOrder} className="bg-green-600 text-white py-2 px-4">
        Confirm Order
      </button>
    </div>
  );
};

export default OrderReview;
